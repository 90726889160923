<template>
  <div class="page" role="main" :aria-label="title" :tests-id="`PageContent-${title}`">
    <div class="container" :style="containerStyles">
      <div class="section" :style="_sectionStyles">
        <h3 v-if="title">{{ title }}</h3>
        <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*
  <PageContent 
    title="title"
  >
  
  
  </PageContent>
*/

import { mapState } from 'vuex'
import _merge from 'lodash/merge'
import _get from 'lodash/get'

export default {
  props: {
    title: String,
    subtitle: String,
    sectionStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    containerStyles: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      // _get is actually only for testing purposes
      // so that the win(dow) store doesn't need
      // to be added to every unit test
      breakpoint: state => _get(state, ['win', 'breakpoint'], null)
    }),
    _sectionStyles() {
      const breakpoint = this.breakpoint

      return _merge(
        {},
        {
          ...(breakpoint === 'mobile' && { padding: '.25em' })
        },
        this.sectionStyles
      )
    }
  }
}
</script>
