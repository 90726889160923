<template>
  <PageContent>
    <div class="box is-12 gradient-bg">
      <div class="welcome-to-homeowner pb-4">
        <div class="text-style-1">Welcome to</div>
        <div>Homeowner Setup</div>
      </div>
      <div class="items-center">
        <div @submit.prevent="onFormSubmit" class="form box white-box">
          <div class="step-1-find-your-cod">
            <span class="text-style-1">Step 1</span>
            <div>Find your code</div>
          </div>
          <div class="your-unique-setup-co force-left">
            Your unique setup code can be found in the upper-right corner of your billing statement
            next to the QR code as shown below.
          </div>
          <div class="">
            <img
              height="100%"
              width="100%"
              style="float: left !important; text-align: left !important;"
              :src="require('@/assets/images/qrcode.png')"
            />
          </div>
          <div>
            <button
              type="button"
              @click.prevent="redirectNextPage"
              class="button is-primary is-rounded"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div class="image-padding">
        <img
          class="hero-image"
          height="100%"
          width="100%"
          style="min-height: 200px;"
          :src="require('@/assets/images/onboarding-couch.png')"
        />
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import { mapState } from 'vuex'

let DEFAULT_FORM_VALUES = {
  ownerSetupCode: ''
}

export default {
  components: {
    PageContent
  },

  data: function() {
    return {
      isDebug: true,
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      }
    }
  },

  async mounted() {
    this.resetForm()
  },

  watch: {
    hoaId() {
      this.resetForm()
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  methods: {
    resetForm() {
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    redirectNextPage() {
      this.$router
        .push({
          name: 'signupwithcodetwo'
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.gradient-bg {
  width: 100%;
  height: 100%;
  margin: 0 2px 0 0;
  padding: 100 203.4px 0 0;
  background-image: linear-gradient(126deg, #2662d6 2%, #133a86 99%);
}

.image-padding {
  padding-top: 10px !important;
  padding-left: 290px !important;
}

.rectangle {
  margin: 15.8px 0 0;
  padding: 32.2px 101.6px 285px 169px;
  transform: rotate(0deg);
  mix-blend-mode: screen;
  border-radius: 430px;
  background-color: #1a47b0;
}

.welcome-to-homeowner {
  width: auto;
  height: auto;
  font-family: Helvetica;
  font-size: 39px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  text-align: center !important;
  color: #fff;
}

.welcome-to-homeowner .text-style-1 {
  font-size: 31px;
  font-weight: normal;
  color: #2cabe1;
  text-align: center !important;
}

.step-1-find-your-cod {
  text-align: left;
  margin: 0 0 16px 0;
  font-family: Helvetica;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #112d6f;
}

.step-1-find-your-cod .text-style-1 {
  font-size: 26px;
  font-weight: normal;
  color: #2cabe1;
}

.your-unique-setup-co {
  margin: 16px 14.8px 11px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #838383;
  float: left;
}

.white-box {
  width: 90%;
  max-width: 660px;
  height: auto;
  text-align: center !important;
  float: center !important;
  background-position: center;
}

.items-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hero-image {
  display: none;
}

@media screen and (min-width: 1024px) {
  .hero-image {
    display: block;
  }
}
</style>
